import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Step, StepButton, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { IsAuthed } from "../../utilities/general";
import { BonusType, Language, PromotionCodeMessage, PromotionCodeType } from "../../constants/options";
import { IsEmptyStr } from "../../utilities/field-validation";

const BonusStep = observer(({ onApplyBonus, orderPrice }: { onApplyBonus: (bonusInfo: BonusType, deduction: number, promotionType?: PromotionCodeType) => void, orderPrice: number }) => {
  const { t }: any = useTranslation();
  const { userStore, bookingStore } = useStores();
  const [bonusType, setBonusType] = useState<BonusType>(BonusType.NOT_SELECTED);
  const [promotionCode, setPromotionCode] = useState<string>("");
  const [rewardPoint, setRewardPoint] = useState<string>("");
  const [rewardErr, setRewardErr] = useState<string>("");

  useEffect(() => {
    setPromotionCode("");
    setRewardPoint("");
    if (IsAuthed()) userStore.getRewardPointTotal();
  }, []);

  const isRewardPointValid = (point: number) => {
    return point >= userStore.rewardPoint.minimumPaymentPoint && point <= userStore.rewardPoint.totalPoint
      && orderPrice > 0 && +(userStore.rewardPoint.pointDollarRate * point).toFixed(2) <= orderPrice;
  }

  const requestPromotionCodeInfo = () => {
    if (promotionCode !== "") {
      bookingStore.getPromotionDetail(promotionCode, IsAuthed() ? JSON.parse(localStorage.USER_INFO).id : 0)
        .then(() => {
          if (bookingStore.promotionCodeInfo.isValid) {
            onApplyBonus(bonusType, bookingStore.promotionCodeInfo.rate,
              bookingStore.promotionCodeInfo.type === PromotionCodeType.AMOUNT ? PromotionCodeType.AMOUNT : PromotionCodeType.DISCOUNT)
          } else onApplyBonus(BonusType.NOT_SELECTED, 0);
        });
    }
  }

  const setBonusToDefault = () => onApplyBonus(BonusType.NOT_SELECTED, 0);
  const onChangeBonusType = (type: BonusType) => {
    setBonusType(type);
    if (type == BonusType.REWARD_PT) {
      if (isRewardPointValid(+rewardPoint)) onApplyBonus(type, userStore.rewardPoint.pointDollarRate * +rewardPoint);
      else setBonusToDefault();
    } else if (type == BonusType.PROMOTION_CODE) {
      if (bookingStore.promotionCodeInfo && bookingStore.promotionCodeInfo.isValid) {
        onApplyBonus(type, bookingStore.promotionCodeInfo.rate,
          bookingStore.promotionCodeInfo.type === PromotionCodeType.AMOUNT ? PromotionCodeType.AMOUNT : PromotionCodeType.DISCOUNT);
      }
    } else {
      onApplyBonus(type, 0);
    }
  }

  return (
    <Box>
      <Box className="shadow-md border">
        <button type="button" className="w-full" onClick={() => onChangeBonusType(BonusType.PROMOTION_CODE)}>
          <ListItem alignItems="flex-start" className="text-4xl">
            <span className={bonusType === BonusType.PROMOTION_CODE ? 'text-orange-500' : 'text-black'}>
              {bonusType === BonusType.PROMOTION_CODE ? <CheckCircleIcon fontSize="inherit" color="inherit" />
                : <Brightness1OutlinedIcon fontSize="inherit" />}
            </span>
            <ListItemText className="text-left ml-4">
              <p className="text-lg 2xl:text-xl text-sky-500 mb-2">{t('PROMOTION_CODES')}</p>
              <Box sx={ITEM_PERFECT_INLINED}>
                <TextField
                  variant="outlined"
                  sx={{ width: 200 }}
                  value={promotionCode}
                  onChange={(event) => setPromotionCode(event.target.value)}
                />
                <Button onClick={requestPromotionCodeInfo} variant="outlined" sx={{ marginLeft: 2 }} size="large">{t('APPLY')}</Button>
              </Box>
              {bookingStore.loading ? <Box textAlign={"center"}><CircularProgress size="small" /></Box>
                : bookingStore.promotionCodeInfo && <Box className="mt-4">
                  <Divider />
                  <p className="pt-4">{t('PROMOTION_CODE')}:
                    <span className={`ml-2 text-green-500`}>{t(bookingStore.promotionCodeInfo.promotionCode)}</span>
                  </p>
                  <p>{t('PROMOTION_CODE_INFO')}:
                    <span className={`ml-2 ${bookingStore.promotionCodeInfo.isValid ? "text-green-500" : "text-red-500"}`}>
                      {t(bookingStore.promotionCodeInfo.message)}
                    </span>
                  </p>
                  {bookingStore.promotionCodeInfo.isValid &&
                    <p>{t('PROMOTION_TYPE')}: <span className="text-red-500">{bookingStore.promotionCodeInfo.type} {bookingStore.promotionCodeInfo.type == PromotionCodeType.AMOUNT
                      ? `-$${bookingStore.promotionCodeInfo.rate}` : `-${bookingStore.promotionCodeInfo.rate}%`}</span></p>}
                </Box>}
            </ListItemText>
          </ListItem>
        </button>
        <Divider />
        {IsAuthed() && <button type="button" className="w-full" onClick={() => onChangeBonusType(BonusType.REWARD_PT)}>
          <ListItem alignItems="flex-start" className="text-4xl">
            <span className={bonusType === BonusType.REWARD_PT ? 'text-orange-500' : 'text-black'}>
              {bonusType === BonusType.REWARD_PT ? <CheckCircleIcon fontSize="inherit" color="inherit" />
                : <Brightness1OutlinedIcon fontSize="inherit" />}
            </span>
            <ListItemText className="text-left ml-4">
              <p className="text-lg 2xl:text-xl text-sky-500">{userStore.rewardPoint.totalPoint} {t('REWARD_POINTS')} (<span className="text-red-500">${(userStore.rewardPoint.totalPoint * userStore.rewardPoint.pointDollarRate).toFixed(2)}</span>)</p>
              {/* <p className="mb-2">{t('TOTAL_REWARD_PT')}:</p> */}
              {userStore.rewardPoint.totalPoint > 0 && <Box sx={ITEM_PERFECT_INLINED}>
                <TextField
                  variant="outlined"
                  type="number"
                  error={rewardErr !== ""}
                  sx={{ width: 200 }}
                  value={rewardPoint}
                  label={t('USE')}
                  helperText={rewardErr}
                  onChange={(event) => {
                    let point = +event.target.value;
                    setRewardPoint(point.toString());
                    if (isRewardPointValid(point)) {
                      setRewardErr("");
                      bookingStore.usedRewardPoint = point;
                      if (bonusType == BonusType.REWARD_PT) onApplyBonus(bonusType, userStore.rewardPoint.pointDollarRate * point);
                    } else {
                      if (point < userStore.rewardPoint.minimumPaymentPoint) setRewardErr(`${t('MINIMUM_PAYMENT_POINT')}: ${userStore.rewardPoint.minimumPaymentPoint}`);
                      if (point > userStore.rewardPoint.totalPoint) setRewardErr(t('YOU_DONT_HAVE_ENOUGH_POINTS'));
                      if (orderPrice > 0 && +(userStore.rewardPoint.pointDollarRate * point).toFixed(2) > orderPrice) setRewardErr(t('AMOUNT_YOU_EXCHANGED_EXCEEDS_THE_TOTAL_ORDER_PRICE'));
                      setBonusToDefault();
                    }
                  }}
                  InputProps={{ inputProps: { min: userStore.rewardPoint.minimumPaymentPoint, max: userStore.rewardPoint.totalPoint } }}
                />
                <p className="ml-4">{t('WORTHING')}: <span className="text-red-500">${(userStore.rewardPoint.pointDollarRate * +rewardPoint).toFixed(2)}</span></p>
              </Box>}
            </ListItemText>
          </ListItem>
        </button>}
      </Box>
      <Box className="flex justify-end mt-4">
        <VmButton onClick={() => onChangeBonusType(BonusType.NOT_SELECTED)}>{t('CANCEL_BONUS')}</VmButton>
      </Box>
    </Box>
  )
});

export default BonusStep;
