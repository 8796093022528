import React, { useEffect, useState } from "react"
import Layout from "../shared-layout";
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Step, StepButton, StepContent, StepLabel, Stepper, Switch, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { IsAuthed } from "../../utilities/general";
import { Gender, Language } from "../../constants/options";
import { PhoneArea } from "../../utilities/check-out";
import { toJS } from "mobx";
import { IsEmptyStr, IsValidEmailAddress } from "../../utilities/field-validation";

const ContactInfoStep = observer(({ showError }: { showError: boolean }) => {
  const { t }: any = useTranslation();
  const { bookingStore } = useStores();
  const [phoneArea, setPhoneArea] = useState<string>("");
  const [refresh, setRefresh] = useState<any>([]);
  // Contact Info Step
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [gender, setGender] = useState<Gender>(Gender.NOT_SELECTED);
  const [email, setEmail] = useState<string>("");
  const [phone, setPhone] = useState<string>("");
  const [createAccount, setCreateAccount] = useState<boolean>(false);
  const [subscribe, setSubscribe] = useState<boolean>(true);
  const [createAccountInfo, setCreateAccountInfo] = useState<any>({ password: "", confirmPassword: "" });

  useEffect(() => {
    bookingStore.contactInfoData = {
      firstName: "",
      surname: "",
      gender: Gender.NOT_SELECTED,
      email: "",
      phoneArea: "",
      phone: "",
      createAccount: false,
      subscribe: true,
      password: "",
      confirmPassword: "",
    }
    setFirstName("");
    setLastName("");
    setGender(Gender.NOT_SELECTED);
    setEmail("");
    setPhone("");
    setCreateAccount(false);
    setSubscribe(true);
    setCreateAccountInfo({ password: "", confirmPassword: "" });

    if (IsAuthed()) {
      const info: any = JSON.parse(localStorage.USER_INFO);
      setFirstName(info.firstName);
      setLastName(info.lastName);
      setEmail(info.email);
      setPhone(info.phone);
      setPhoneArea(info.phoneArea);
      setGender(info.gender == Gender.MALE ? Gender.MALE : Gender.FEMALE);
      bookingStore.contactInfoData.firstName = info.firstName;
      bookingStore.contactInfoData.surname = info.lastName;
      bookingStore.contactInfoData.email = info.email;
      bookingStore.contactInfoData.phone = info.phone;
      bookingStore.contactInfoData.gender = info.gender;
      bookingStore.contactInfoData.phoneArea = info.phoneArea;
      console.log(bookingStore.contactInfoData);

    }
  }, []);

  const onChangeGender = (event: React.ChangeEvent<HTMLInputElement>) => {
    setGender((event.target as any).value);
    bookingStore.contactInfoData.gender = (event.target.value as any) == Gender.MALE ? Gender.MALE : Gender.FEMALE;
  }
  const onChangePhoneArea = (event: SelectChangeEvent) => {
    setPhoneArea(event.target.value as string);
    bookingStore.contactInfoData.phoneArea = event.target.value as string;
  }
  const onChangeCreateAccount = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCreateAccount(event.target.checked);
    bookingStore.contactInfoData.createAccount = event.target.checked;
  }

  const onChangeSubscribe = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSubscribe(event.target.checked);
    bookingStore.contactInfoData.subscribe = event.target.checked;
  };

  return (
    <Box className="grid grid-cols-2 gap-4 mt-4">
      <Box className="grid grid-cols-2 gap-2">
        <TextField
          label={t('FIRST_NAME') + ' *'}
          variant="outlined"
          value={firstName}
          onChange={(event) => {
            setFirstName(event.target.value);
            bookingStore.contactInfoData.firstName = event.target.value;
          }}
          error={showError && IsEmptyStr(firstName)}
        />
        <TextField
          label={t('SURNAME') + ' *'}
          variant="outlined"
          value={lastName}
          onChange={(event) => {
            setLastName(event.target.value);
            bookingStore.contactInfoData.surname = event.target.value;
          }}
          error={showError && IsEmptyStr(lastName)}
        />
      </Box>
      <Box>
        <p className={`${showError && gender == Gender.NOT_SELECTED && 'text-red-500'}`}>{t('GENDER') + ' *'}</p>
        <RadioGroup row name="row-radio-buttons-group" value={gender} onChange={onChangeGender}>
          <FormControlLabel value={Gender.MALE} control={<Radio />} label={t('MALE')} />
          <FormControlLabel value={Gender.FEMALE} control={<Radio />} label={t('FEMALE')} />
        </RadioGroup>
      </Box>
      <Box className="col-span-2 sm:col-span-1">
        <TextField
          label={t('EMAIL') + ' *'}
          variant="outlined"
          value={email}
          onChange={(event) => {
            setEmail(event.target.value);
            bookingStore.contactInfoData.email = event.target.value;
          }}
          error={showError && (IsEmptyStr(email) || !IsValidEmailAddress(email))}
          helperText={showError && !IsValidEmailAddress(email) ? t('INVALID_EMAIL_FORMAT') : ''}
        />
      </Box>
      <Box className="col-span-2 sm:col-span-1 grid grid-cols-3 gap-2">
        <FormControl error={showError && IsEmptyStr(bookingStore.contactInfoData.phoneArea)}>
          <InputLabel>{t('AREA_CODE') + ' *'}</InputLabel>
          <Select
            label={t('AREA_CODE') + ' *'}
            value={phoneArea}
            size="medium"
            onChange={onChangePhoneArea}
          >
            {PhoneArea.map((pa: any, i: number) => (pa.value === "" ? <MenuItem disabled value="" className="bg-gray-600 text-white">{t('OTHER_COUNTRIES_DISTRICT')}</MenuItem>
              : <MenuItem key={`phone_area_${i}`} value={pa.value}>{pa.label}</MenuItem>))}
          </Select>
        </FormControl>
        <Box className="col-span-2">
          <TextField
            label={t('NUMBER') + ' *'}
            variant="outlined"
            value={phone}
            onChange={(event) => {
              setPhone(event.target.value);
              bookingStore.contactInfoData.phone = event.target.value;
            }}
            error={showError && IsEmptyStr(phone)}
          />
        </Box>
      </Box>
      {!IsAuthed() && <>
        <Box>
          <Typography>{t('WOULD_YOU_LIKE_TO_CREATE_AN_ACCOUNT')}</Typography>
          <Box className="grid grid-cols-2 gap-2">
            <Box sx={ITEM_PERFECT_INLINED}>
              <p className="mr-4">{t('NO')}</p>
              <RadioGroup row name="create-account-radio-buttons-group" value={createAccount} onChange={onChangeCreateAccount}>
                <FormControlLabel
                  label={t('YES')}
                  control={<Switch
                    checked={createAccount}
                    onChange={onChangeCreateAccount}
                    color="primary"
                  />} />
              </RadioGroup>
            </Box>
          </Box>
        </Box>
        {createAccount && <>
          <Box />
          <FormControlLabel label={t('AGREE_TO_SUBSCRIBE_TOUR61')}
            control={<Switch
              checked={subscribe}
              onChange={onChangeSubscribe}
              color="primary"
            />} />
          <Box />
          <TextField
            label={t('PASSWORD')}
            variant="outlined"
            type="password"
            autoComplete="off"
            value={createAccountInfo.password}
            onChange={(event) => {
              createAccountInfo.password = event.target.value;
              bookingStore.contactInfoData.password = event.target.value;
              setRefresh([]);
            }}
            error={showError && (IsEmptyStr(createAccountInfo.password) || createAccountInfo.password != createAccountInfo.confirmPassword)}
            helperText={showError && createAccountInfo.password != createAccountInfo.confirmPassword ? t('TWO_PASSWORDS_NOT_MATCH') : ''}
          />
          <TextField
            label={t('CONFIRM_PASSWORD')}
            variant="outlined"
            type="password"
            autoComplete="off"
            value={createAccountInfo.confirmPassword}
            onChange={(event) => {
              createAccountInfo.confirmPassword = event.target.value;
              bookingStore.contactInfoData.confirmPassword = event.target.value;
              setRefresh([]);
            }}
            error={showError && (IsEmptyStr(createAccountInfo.confirmPassword) || createAccountInfo.password != createAccountInfo.confirmPassword)}
          />
        </>}
      </>}
    </Box>
  )
});

export default ContactInfoStep;
