import React, { useEffect, useRef, useState } from "react"
import Layout from "../../components/shared-layout";
import { Box, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, InputLabel, listClasses, ListItem, ListItemText, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Step, StepButton, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../../components/product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../../components/shared-button";
import { navigate } from "gatsby";
import { CheckoutSteps, IsErrorInBookingFields, IsErrorInContactInfoFields, WebOrder, WebOrderProduct } from "../../utilities/check-out";
import useStores from "../../hooks/use-stores";
import { GetLanguage, IsAuthed } from "../../utilities/general";
import { BonusType, Gender, Language, PaymentType, PromotionCodeType } from "../../constants/options";
import ContactInfoStep from "../../components/check-out/contact-info-step";
import BookingDetailStep from "../../components/check-out/booking-detail-step";
import BonusStep from "../../components/check-out/bonus-step";
// @ts-ignore
import CreditCardImg from "../../images/visa-mastercard-amex.png";
// @ts-ignore
import PoliImg from "../../images/poli-logo.png";
// @ts-ignore
import PayPalImg from "../../images/paypal.jpg";
import { isWeb } from "../../utilities/platform";
import { AUDateTimeFormat, AUDDateFormat, CalculateCartItemTotalPrice, CalculateCartItemTotalPriceForPromotion, CartItemList, IsExpiredProduct } from "../../utilities/cart-item";
import moment from "moment";
import { toJS } from "mobx";
import CheckOutStepper from "../../components/checkout-stepper";
import { IsEmptyStr } from "../../utilities/field-validation";

const CheckOutPage = observer(() => {
  const { t }: any = useTranslation();
  const { rootStore, cartStore, bookingStore } = useStores();
  const [activeStep, setActiveStep] = useState<number>(0);
  const [paymentType, setPaymentType] = useState<PaymentType>(PaymentType.NOT_SELECTED);
  const [summaryCartList, setSummaryCartList] = useState<CartItemList>({ totalPrice: 0, productList: [] });
  const [caculatedTotalPrice, setCaculatedTotalPrice] = useState<number>(0);

  const [bonusType, setBonusType] = useState<BonusType>(BonusType.NOT_SELECTED);
  const [deductionValue, setDeductionValue] = useState<number>(0);
  const [promotionCodeType, setPromotionCodeType] = useState<PromotionCodeType | null>();
  const [showError, setShowError] = useState<boolean>(false);
  const contactInfoRef = useRef(null);

  useEffect(() => {
    setBonusType(BonusType.NOT_SELECTED);
    const isProductListEmpty = (list: any) => list.filter((p: any) => !IsExpiredProduct(p.startDate.split('T')[0], p.scheduleTime)).length == 0;
    if (IsAuthed()) {
      if (cartStore.cartList.productList.length == 0) {
        cartStore.getCartItemList(GetLanguage())
          .then((cartList: any) => {
            if (isProductListEmpty(cartList.productList)) navigate('/cart');
            else {
              bookingStore.getBookingField(GetLanguage(), cartList.productList.filter((p: any) => !IsExpiredProduct(p.startDate.split('T')[0], p.scheduleTime)));
              setSummaryCartList(cartList);
              setCaculatedTotalPrice(CalculateCartItemTotalPrice(cartList.productList));
            }
          });
      } else {
        if (isProductListEmpty(cartStore.cartList.productList)) navigate('/cart');
        else {
          bookingStore.getBookingField(GetLanguage(), cartStore.cartList.productList.filter((p: any) => !IsExpiredProduct(p.startDate.split('T')[0], p.scheduleTime)));
          setSummaryCartList(cartStore.cartList);
          setCaculatedTotalPrice(CalculateCartItemTotalPrice(cartStore.cartList.productList));
        }
      }
    } else {
      if (isWeb && localStorage.CART_LIST) {
        let cartList = JSON.parse(localStorage.CART_LIST);
        if (isProductListEmpty(cartList.productList)) navigate('/cart');
        else {
          bookingStore.getBookingField(GetLanguage(), cartList.productList.filter((p: any) => !IsExpiredProduct(p.startDate.split('T')[0], p.scheduleTime)));
          setSummaryCartList(cartList);
          setCaculatedTotalPrice(CalculateCartItemTotalPrice(cartList.productList));
        }
      } else navigate("/");
    }
  }, []);

  const onApplyBonus = (bonusInfo: BonusType, deduction: number, promotionType?: PromotionCodeType) => {
    deduction = +deduction.toFixed(2);
    if (bonusInfo !== BonusType.PROMOTION_CODE) {
      // @ts-ignore
      setCaculatedTotalPrice((CalculateCartItemTotalPrice(summaryCartList.productList) - deduction).toFixed(2));
      setPromotionCodeType(null);
    }
    else if (bookingStore.promotionCodeInfo) {
      const productId = bookingStore.promotionCodeInfo.productId ? bookingStore.promotionCodeInfo.productId
        : summaryCartList.productList.filter((c: any) => !IsExpiredProduct(c.startDate.split('T')[0], c.scheduleTime))
          .find((p: any) => p.subTotal == Math.max(...summaryCartList.productList.map((p: any) => p.subTotal)))?.productId;
      setPromotionCodeType(promotionType);
      if (promotionType == PromotionCodeType.DISCOUNT) deduction = (100 - deduction) / 100;
      // @ts-ignore
      setCaculatedTotalPrice(CalculateCartItemTotalPriceForPromotion(
        // @ts-ignore
        summaryCartList.productList, promotionType, productId, deduction));
    }
    setBonusType(bonusInfo);
    setDeductionValue(deduction);
  }

  const onCheckErrors = () => {
    let error = false;
    if (IsErrorInContactInfoFields()) {
      // @ts-ignore
      contactInfoRef.current.scrollIntoView(({ behavior: 'smooth' }));
      console.log("first");
      rootStore.notify(t('ONE_OR_MORE_FIELDS_IN_CONTACT_INFO_SECTION_ARE_NOT_FILLED_CORRECTLY'), "warning");
      if (bookingStore.contactInfoData.password != bookingStore.contactInfoData.confirmPassword) rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
      error = true;
    }
    if (IsErrorInBookingFields()) {
      // @ts-ignore
      if (!error) {
        // scroll to the according section position in relevant idx
        bookingStore.bookingFieldViewPositions.current[bookingStore.bookingFieldIdx].current
          .scrollIntoView(({ behavior: 'smooth' }));
      }
      rootStore.notify(t('ONE_OR_MORE_FIELDS_IN_BOOKING_SECTION_ARE_NOT_FILLED_CORRECTLY'), "warning");
      console.log("second");
      error = true;
    }
    if (!error && paymentType == PaymentType.NOT_SELECTED) {
      error = true;
      rootStore.notify(t('PLS_SELECT_YOUR_PAYMENT_METHOD'), "warning");
    }
    setShowError(error);
    return error;
  }

  const onClickCheckOut = () => {
    if (!onCheckErrors()) {
      if (bookingStore.bookingFieldData && !IsEmptyStr(bookingStore.contactInfoData.firstName)) {
        let productList: WebOrderProduct[] = [];
        bookingStore.bookingFieldData.filter((p: any) => !IsExpiredProduct(p.startDate.split('T')[0], p.scheduleTime))
          .map((p: any) => {
            let product: WebOrderProduct = {
              productId: p.productId,
              productName: p.productName,
              priceId: p.priceId,
              startDate: p.startDate,
              schedule: p.scheduleTime,
              remark: p.remark,
              subTotal: p.subTotal,
              quantityObject: p.quantities,
              apiFields: p.api ? p.api : {},
            }
            if (p.pickUpOption) product.hotel = p.pickUpOption;
            if (p.flightInfo) {
              product.flightObject = {
                depdate: p.flightInfo.departureDate,
                deptime: p.flightInfo.depatureTime,
                depflight: p.flightInfo.depatureFlightNo,
                deptype: p.flightInfo.depatureFlightType,
                retdate: p.flightInfo.returnDate,
                rettime: p.flightInfo.returnDepartureTime,
                retflight: p.flightInfo.returnDepartureFlightNo,
                rettype: p.flightInfo.returnDepartureFlightType
              }
            }
            product.dynamicFields = p.dynamicFields ? p.dynamicFields : {};
            productList.push(product);
          });
        let req: WebOrder = {
          customerId: IsAuthed() ? JSON.parse(localStorage.USER_INFO).id : 0,
          contactFirstname: bookingStore.contactInfoData.firstName,
          contactSurname: bookingStore.contactInfoData.surname,
          contactGender: bookingStore.contactInfoData.gender,
          contactEmail: bookingStore.contactInfoData.email,
          contactPhoneArea: bookingStore.contactInfoData.phoneArea,
          contactPhone: bookingStore.contactInfoData.phone,
          createAccount: bookingStore.contactInfoData.createAccount,
          subscribe: bookingStore.contactInfoData.subscribe,
          originalTotal: CalculateCartItemTotalPrice(summaryCartList.productList),
          paymentMethod: paymentType,
          productList,
        }
        if (bookingStore.contactInfoData.createAccount && bookingStore.contactInfoData.password != bookingStore.contactInfoData.confirmPassword) {
          rootStore.notify(t('TWO_PASSWORDS_NOT_MATCH'), 'warning');
          return;
        }
        if (bookingStore.contactInfoData.createAccount) req.password = bookingStore.contactInfoData.password;
        console.log(req);
        if (bonusType == BonusType.PROMOTION_CODE && bookingStore.usedPromotionCode !== "") req.promotionCode = bookingStore.usedPromotionCode;
        else if (bonusType == BonusType.REWARD_PT && bookingStore.usedRewardPoint > 0) req.rewardPoint = bookingStore.usedRewardPoint;
        // return;
        bookingStore.createWebOrderAndGetPaymentUrl(req)
          .then((paymentUrl: any) => navigate(paymentUrl.redirectUrl))
          .catch((error) => rootStore.notify(error.response.data.message, "error"));
      }
    }
  }

  return (
    <Layout pageName={t('CHECK_OUT')}
      breadCrumb={[
        { to: "/", label: t('HOME') },
        { to: "/cart", label: t('SHOPPING_CART') },
        { to: "", label: t('CHECK_OUT') }
      ]} showBanner={false}>
      {bookingStore.loadingPayment && <Box className="fixed top-0 left-0 right-0 bottom-0 z-50" style={{ backgroundColor: 'rgba(0,0,0,0.7)' }}>
        <Box className="absolute top-1/2 w-full text-center translate-y-neg-1/2">
          <CircularProgress />
          <p className="text-white text-2xl 2xl:text-3xl mt-4">{t('PROCESSING_ORDER')}...</p>
          <p className="text-white text-2xl 2xl:text-3xl mt-2">{t('PLEASE_DONT_LEAVE_THE_PAGE')}</p>
        </Box>
      </Box>}
      <Box className={PADDING_HORIZONTAL}>
        <Box ref={contactInfoRef}><CheckOutStepper activeStep={1} /></Box>
        <Box className="grid grid-cols-3 gap-6">
          {/* Start Left Section */}
          <Box className="col-span-3 sm:col-span-2">
            <Stepper nonLinear orientation="vertical">
              {CheckoutSteps.map((step: any, index: number) => (
                <Step key={step.label + `_${index}`} active>
                  {/* <StepButton onClick={() => setActiveStep(index)}> */}
                  <StepLabel className="underline cursor-pointer text-lg 2xl:text-xl">{step.label}</StepLabel>
                  {/* </StepButton> */}
                  <StepContent>
                    {index == 0 ? <ContactInfoStep showError={showError} />
                      : index == 1 ? <BookingDetailStep showError={showError} />
                        : index == 2 ? <BonusStep onApplyBonus={onApplyBonus} orderPrice={CalculateCartItemTotalPrice(summaryCartList.productList)} />
                          : <Box className="shadow-md border">
                            <button type="button" className="w-full" onClick={() => setPaymentType(PaymentType.CREDIT_CARD)}>
                              <ListItem alignItems="flex-start" className="text-4xl">
                                <span className={paymentType === PaymentType.CREDIT_CARD ? 'text-orange-500' : 'text-black'}>
                                  {paymentType === PaymentType.CREDIT_CARD ? <CheckCircleIcon fontSize="inherit" color="inherit" />
                                    : <Brightness1OutlinedIcon fontSize="inherit" />}
                                </span>
                                <ListItemText className="text-left ml-4">
                                  <p>{t('CREDIT_CARD')}</p>
                                  <img src={CreditCardImg} className="w-60" />
                                </ListItemText>
                              </ListItem>
                            </button>
                            <Divider />
                            <button type="button" className="w-full" onClick={() => setPaymentType(PaymentType.POLI)}>
                              <ListItem alignItems="flex-start" className="text-4xl">
                                <span className={paymentType === PaymentType.POLI ? 'text-orange-500' : 'text-black'}>
                                  {paymentType === PaymentType.POLI ? <CheckCircleIcon fontSize="inherit" color="inherit" />
                                    : <Brightness1OutlinedIcon fontSize="inherit" />}
                                </span>
                                <ListItemText className="text-left ml-4">
                                  <p>{t('POLI')}</p>
                                  <img src={PoliImg} className="w-32" />
                                </ListItemText>
                              </ListItem>
                            </button>
                            <Divider />
                            <button type="button" className="w-full" onClick={() => setPaymentType(PaymentType.PAYPAL)}>
                              <ListItem alignItems="flex-start" className="text-4xl">
                                <span className={paymentType === PaymentType.PAYPAL ? 'text-orange-500' : 'text-black'}>
                                  {paymentType === PaymentType.PAYPAL ? <CheckCircleIcon fontSize="inherit" color="inherit" />
                                    : <Brightness1OutlinedIcon fontSize="inherit" />}
                                </span>
                                <ListItemText className="text-left ml-4">
                                  <p>PayPal</p>
                                  <img src={PayPalImg} className="w-32" />
                                </ListItemText>
                              </ListItem>
                            </button>
                          </Box>}
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </Box>
          {/* End Left Section */}
          {/* Start Right Section */}
          <Box className="col-span-3 sm:col-span-1">
            <Box className="border-yellow-100 shadow-md" style={{ backgroundColor: '#fffef4' }}>
              <Box className="p-4 text-lg 2xl:text-xl">{t('SUMMARY')}</Box>
              {summaryCartList.totalPrice > 0 && <>
                <Box className="px-4">
                  {summaryCartList.productList.filter((p: any) => !IsExpiredProduct(p.startDate.split('T')[0], p.scheduleTime)).map((product: any, i: number) => (
                    <Box key={`summary_product_${i}`} className="border-dashed border-t-2 py-2">
                      <p className="text-sm sm:text-base 2xl:text-lg">{product.productName}</p>
                      <p className="font-light text-xs sm:text-sm text-2xl:text-base text-gray-400 my-1">{moment(product.startDate.split('T')[0] + (product.scheduleTime ? `T${product.scheduleTime}` : ""))
                        .format(!product.scheduleTime || product.scheduleTime === "" ? AUDDateFormat : AUDateTimeFormat)}</p>
                      <p className="text-sm sm:text-base font-light">{product.totalQuantity.map((qty: any, qtyIdx: number) => (
                        <span key={`total_qty_${qtyIdx}`} className="">{qty.priceName} ${qty.unitPrice} x {qty.quantity} {qtyIdx + 1 != product.totalQuantity.length && '| '}</span>
                      ))}</p>
                      {(bonusType === BonusType.PROMOTION_CODE && (
                        (bookingStore.promotionCodeInfo && bookingStore.promotionCodeInfo.productId && bookingStore.promotionCodeInfo.productId == product.id) ||
                        product.subTotal == Math.max(...summaryCartList.productList.map((p: any) => p.subTotal))
                      )) &&
                        <Box textAlign={"right"} className="text-sm sm:text-base font-bold text-red-500">
                          {t('PROMOTION_DISCOUNT')}: {promotionCodeType == PromotionCodeType.AMOUNT ? `-A$${deductionValue}` :
                            promotionCodeType == PromotionCodeType.DISCOUNT && `-${100 - (deductionValue * 100)}%`}
                        </Box>}
                      <Box textAlign={"right"} className="font-bold text-red-500">
                        = A${(bonusType === BonusType.PROMOTION_CODE && (
                          (bookingStore.promotionCodeInfo && bookingStore.promotionCodeInfo.productId && bookingStore.promotionCodeInfo.productId == product.id) ||
                          product.subTotal == Math.max(...summaryCartList.productList.map((p: any) => p.subTotal))
                        )) ? promotionCodeType == PromotionCodeType.AMOUNT ? (product.subTotal - deductionValue).toFixed(2)
                          : (product.subTotal * deductionValue).toFixed(2)
                          : product.subTotal.toFixed(2)}
                      </Box>
                    </Box>
                  ))}
                  {bonusType == BonusType.REWARD_PT && <>
                    <Box className="border-dashed border-t-2 py-2">
                      <p className="text-sm sm:text-base 2xl:text-lg">{t('REWARD_POINT_DISCOUNT')}</p>
                      <Box textAlign={"right"} className="font-bold text-red-500">-A${deductionValue.toFixed(2)}</Box>
                    </Box>
                  </>}
                </Box>
                <Box className="p-4 mt-4" style={{ backgroundColor: '#fffef4', boxShadow: '0px -5px 8px 0px #eae8d4' }}>
                  <Box sx={ITEM_PERFECT_INLINED} className="justify-between">
                    <p className="text-lg 2xl:text-xl">{t('TOTAL_COST')}</p>
                    <p className="text-red-500 font-bold text-2xl 2xl:text-3xl"> A${caculatedTotalPrice}</p>
                  </Box>
                </Box>
              </>}
            </Box>
          </Box>
        </Box>
        <p className="text-sm sm:text-base mt-4 text-center">{t('READ_TERMS_CONDITION_POLICY')}</p>
        <Box className="grid grid-cols-3 gap-4 sm:gap-8 mt-4 shadow-lg">
          <Box sx={ITEM_PERFECT_INLINED} className="justify-end col-span-2 py-4">
            <p className="uppercase">{t('TOTAL_COST')}</p>
            <p className="text-red-500 font-bold ml-6 text-3xl 2xl:text-4xl">A${caculatedTotalPrice}</p>
          </Box>
          <Box>
            <VmButton fitContent={false} onClick={onClickCheckOut} bg="bg-orange-500" borderColor="border-orange-500" className="w-full h-full">
              {t('PAY_NOW_PLACE_ORDER')}
            </VmButton>
          </Box>
        </Box>
      </Box>
    </Layout>
  )
});

export default CheckOutPage;
