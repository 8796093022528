import React, { createRef, useEffect, useRef, useState } from "react"
import Layout from "../shared-layout";
import { Box, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, SelectChangeEvent, setRef, Skeleton, Step, StepButton, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import { observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// @ts-ignore
import Brightness1OutlinedIcon from '@mui/icons-material/Brightness1Outlined';
import { ITEM_PERFECT_INLINED, PADDING_HORIZONTAL, THEME_YELLOW } from "../../constants/style";
import ProductCard from "../product-card";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import VmButton from "../shared-button";
import { navigate } from "gatsby";
import useStores from "../../hooks/use-stores";
import { IsAuthed } from "../../utilities/general";
import { FlightInfoFieldType, FlightType, Gender, Language, TravellerFieldType } from "../../constants/options";
import moment from "moment";
import { TravellerInfo } from "../../utilities/check-out";
import { toJS } from "mobx";
import { IsEmptyStr } from "../../utilities/field-validation";
import { API_PRODUCT_METHOD } from "../../constants/options";

const BookingDetailStep = observer(({ showError }: { showError: boolean }) => {
  const { t }: any = useTranslation();
  const { bookingStore, userStore } = useStores();
  const fieldLabel = "lg:text-sm 2xl:text-base mt-4 mb-2"
  const [bookingField, setBookingField] = useState<any[]>([]);
  // every product has special requirement field
  const [specialRequirement, setSpecialRequirement] = useState<string>("");
  const [flightInfo, setFlightInfo] = useState<any>({});
  const [refresh, setRefresh] = useState<any>([]);
  const [pickUpAddress, setPickUpAddress] = useState<string>("");
  const refPositions = useRef([]);


  useEffect(() => {
    if (bookingStore.bookingField) {
      refPositions.current = [...Array(bookingStore.bookingField.length).keys()].map(
        (_, i) => refPositions.current[i] ?? createRef());
      const field = bookingStore.bookingField;
      if (field.filter((p: any) => p.bookingFields.travellerInfo.requireTraveller).length > 0) {
        field.map((p: any, i: number) => {
          if (IsAuthed() && p.bookingFields.travellerInfo.requireTraveller) {
            const info = JSON.parse(localStorage.USER_INFO);
            modifyTravellerInfo(TravellerFieldType.FIRST_NAME, info.firstName, i, 0, field);
            modifyTravellerInfo(TravellerFieldType.SURNAME, info.lastName, i, 0, field);
            modifyTravellerInfo(TravellerFieldType.GENDER, info.gender.toString(), i, 0, field);
          }
        })
      }
      setBookingField(field);
    }
  }, [bookingStore.bookingField]);

  useEffect(() => {
    bookingStore.bookingFieldViewPositions = refPositions;
    bookingStore.bookingFieldData = bookingField;
    setRefresh([]);
  }, [bookingField]);

  // Pick up options
  const onChangePickUpOption = (event: SelectChangeEvent, productIdx: number) => {
    bookingField[productIdx].pickUpOption = event.target.value as string;
    setBookingField(bookingField);
  }
  // Flight Info
  const modifyFlightInfo = (fieldType: FlightInfoFieldType, value: any, productIdx: number) => {
    flightInfo[fieldType] = value;
    bookingField[productIdx].flightInfo = flightInfo;
    setBookingField(bookingField);
    setRefresh([]);
  }
  // Traveller Info
  const modifyTravellerInfo = (fieldType: TravellerFieldType, value: any, productIdx: number, quantityIdx: number, defaultField?: any) => {
    if (defaultField) defaultField[productIdx].quantities[quantityIdx][fieldType] = value;
    else bookingField[productIdx].quantities[quantityIdx][fieldType] = value;
    setBookingField(bookingField);
  }

  const modifyBookingDynamicInfo = (key: string, value: any, productIdx: number) => {
    if (!bookingField[productIdx].dynamicFields) {
      bookingField[productIdx].dynamicFields = { [key]: value };
    } else {
      bookingField[productIdx].dynamicFields = {
        ...bookingField[productIdx].dynamicFields,
        [key]: value,
      }
    }
    setBookingField(bookingField);
    console.log(toJS(bookingField[productIdx]));
  }

  const modifyTravellerDynamicInfo = (key: string, value: any, productIdx: number, quantityIdx: number) => {
    if (!bookingField[productIdx].quantities[quantityIdx].dynamicFields) {
      bookingField[productIdx].quantities[quantityIdx].dynamicFields = { [key]: value };
    } else {
      bookingField[productIdx].quantities[quantityIdx].dynamicFields = {
        ...bookingField[productIdx].quantities[quantityIdx].dynamicFields,
        [key]: value,
      }
    }
    setBookingField(bookingField);
    console.log(toJS(bookingField[productIdx]));
  }

  return (
    <Box className="mt-2">
      {bookingStore.loading || bookingStore.bookingField.length == 0 ? <Skeleton variant="rectangular" width="100%" height={300} /> : <>
        {bookingField.map((product: any, i: number) => (
          <Box ref={refPositions.current[i]} key={`booking_field_${i}`} className={`mb-8 field_${i}`}>
            <p className="text-xl 2xl:text-2xl"><span className="text-sky-400">{moment(product.startDate).format('D MMM')}</span> | {product.productName}</p>
            <p className="text-lg 2xl:text-xl font-light">{product.priceName}</p>
            {/* Required Pickup */}
            {product.bookingFields.pickUpInfo.requirePickUp && <>
              <p className={fieldLabel}>{product.bookingFields.pickUpInfo.pickUpQuestion} <span className="text-red-500">*</span></p>
              {product.bookingFields.pickUpInfo.pickUpOptions.length > 0 ?
                <FormControl sx={{ width: '100%' }} error={showError && IsEmptyStr(bookingStore.bookingFieldData[i].pickUpOption)}>
                  <InputLabel>{t('SELECT')}</InputLabel>
                  <Select
                    label={t('SELECT')}
                    value={product.pickUpOption}
                    sx={{ width: '100%' }}
                    size="medium"
                    onChange={(event: any) => onChangePickUpOption(event, i)}
                  >
                    {product.bookingFields.pickUpInfo.pickUpOptions.map((pickOption: any, optionIdx: number) => (
                      <MenuItem key={`pick_options_optionIdx_${optionIdx}`} value={pickOption}>{pickOption}</MenuItem>
                    ))}
                  </Select>
                </FormControl> : <TextField
                  rows={2}
                  multiline
                  variant="outlined"
                  value={bookingField[i].pickUpOption}
                  sx={{ width: '100%' }}
                  size="small"
                  onChange={(event) => {
                    setPickUpAddress(event.target.value);
                    bookingField[i].pickUpOption = event.target.value;
                    console.log(toJS(bookingField), i, toJS(bookingField[i]));

                    setBookingField(bookingField);
                  }}
                  error={showError && (bookingStore.bookingFieldData[i] ? IsEmptyStr(bookingStore.bookingFieldData[i].pickUpOption) : true)}
                />}
            </>}
            {/* End Required Pickup */}
            {/* Special Requirement */}
            <TextField
              rows={2}
              multiline
              variant="outlined"
              value={bookingField[i].remark}
              sx={{ width: '100%', marginTop: 2 }}
              size="small"
              label={t('SPECIAL_REQUIREMENT')}
              InputLabelProps={{ shrink: true }}
              onChange={(event) => {
                bookingField[i].remark = event.target.value;
                setBookingField(bookingField);
              }}
            />
            {/* End Special Requirement */}
            {/* Flight Info */}
            {(product.bookingFields.flightInfo.requireFlight || product.bookingFields.flightInfo.requireReturnFlight) && <>
              <p className={fieldLabel}>{product.bookingFields.flightInfo.flightQuestion} <span className="text-red-500">*</span></p>
              <Box className="grid grid-cols-3 gap-4 mt-4 mb-1">
                {/* Departure Flight */}
                <TextField
                  label={t('DEPARTURE_DATE')}
                  variant="outlined"
                  value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.DEPARTURE_DATE] : ""}
                  sx={{ width: '100%' }}
                  size="small"
                  type="date"
                  autoComplete="off"
                  id={`dp_date_${i}`}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => modifyFlightInfo(FlightInfoFieldType.DEPARTURE_DATE, event.target.value, i)}
                  error={showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.departureDate) : true)}
                />
                <TextField
                  label={t('DEPARTURE_TIME')}
                  variant="outlined"
                  value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.DEPARTURE_TIME] : ""}
                  sx={{ width: '100%' }}
                  size="small"
                  type="time"
                  autoComplete="off"
                  id={`dp_time_${i}`}
                  InputLabelProps={{ shrink: true }}
                  onChange={(event) => modifyFlightInfo(FlightInfoFieldType.DEPARTURE_TIME, event.target.value, i)}
                  error={showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.depatureTime) : true)}
                />
                <TextField
                  label={t('DEPARTURE_FLIGHT_NO')}
                  variant="outlined"
                  value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.DEPARTURE_FLIGHT_NO] : ""}
                  sx={{ width: '100%' }}
                  size="small"
                  onChange={(event) => modifyFlightInfo(FlightInfoFieldType.DEPARTURE_FLIGHT_NO, event.target.value, i)}
                  error={showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.depatureFlightNo) : true)}
                />
                <Box className="col-span-3 mb-2">
                  <p className={`${showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.depatureFlightType) : true) && 'text-red-500'}`}>{t('DEPARTURE_FLIGHT_TYPE')}</p>
                  <RadioGroup row name="row-radio-buttons-group" value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.DEPATURE_FLIGHT_TYPE] : ""}
                    onChange={(event) => modifyFlightInfo(FlightInfoFieldType.DEPATURE_FLIGHT_TYPE, event.target.value, i)}>
                    <FormControlLabel value={FlightType.DOMESTIC} control={<Radio />} label={t('DOMESTIC')} />
                    <FormControlLabel value={FlightType.INTERNATIONAL} control={<Radio />} label={t('INTERNATIONAL')} />
                  </RadioGroup>
                </Box>
                {/* End Departure Flight */}
                {/* Return Flight */}
                {product.bookingFields.flightInfo.requireReturnFlight && <>
                  <TextField
                    label={t('RETURN_DATE')}
                    variant="outlined"
                    value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.RETURN_DATE] : ""}
                    sx={{ width: '100%' }}
                    size="small"
                    type="date"
                    id={`return_date_${i}`}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => modifyFlightInfo(FlightInfoFieldType.RETURN_DATE, event.target.value, i)}
                    error={showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.returnDate) : true)}
                  />
                  <TextField
                    label={t('RETURN_TIME')}
                    variant="outlined"
                    value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.RETURN_TIME] : ""}
                    sx={{ width: '100%' }}
                    size="small"
                    type="time"
                    id={`return_time_${i}`}
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => modifyFlightInfo(FlightInfoFieldType.RETURN_TIME, event.target.value, i)}
                    error={showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.returnDepartureTime) : true)}
                  />
                  <TextField
                    label={t('RETURN_FLIGHT_NO')}
                    variant="outlined"
                    value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.RETURN_FLIGHT_NO] : ""}
                    sx={{ width: '100%' }}
                    size="small"
                    onChange={(event) => modifyFlightInfo(FlightInfoFieldType.RETURN_FLIGHT_NO, event.target.value, i)}
                    error={showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.returnDepartureFlightNo) : true)}
                  />
                  <Box className="col-span-3">
                    <p className={`${showError && (bookingStore.bookingFieldData[i].flightInfo ? IsEmptyStr(bookingStore.bookingFieldData[i].flightInfo.returnDepartureFlightType) : true) && 'text-red-500'}`}>{t('RETURN_FLIGHT_TYPE')}</p>
                    <RadioGroup row name="row-radio-buttons-group" value={product.flightInfo ? product.flightInfo[FlightInfoFieldType.RETURN_FLIGHT_TYPE] : ""}
                      onChange={(event) => modifyFlightInfo(FlightInfoFieldType.RETURN_FLIGHT_TYPE, event.target.value, i)}>
                      <FormControlLabel value={FlightType.DOMESTIC} control={<Radio />} label={t('DOMESTIC')} />
                      <FormControlLabel value={FlightType.INTERNATIONAL} control={<Radio />} label={t('INTERNATIONAL')} />
                    </RadioGroup>
                  </Box>
                </>}
                {/* End Return Flight */}
              </Box>
            </>}
            {/* End Flight Info */}
            {/* Required Traveller Info */}
            {(product.bookingFields.travellerInfo.requireTraveller ||
              (product.bookingFields.travellerInfo.travellerDynamicFields && product.bookingFields.travellerInfo.travellerDynamicFields.length > 0)) && <>
                {product.quantities.map((ticket: any, tIndex: number) => (
                  <>
                    <p key={`ticket_info_${tIndex}`} className="col-span-3 mt-4 mb-2">{tIndex == 0 ? t('LEAD_TRAVELLER') : `${t('TRAVELLER')} ${tIndex} (${ticket.priceName})`}:</p>
                    <Box className="grid grid-cols-3 gap-4">
                      <TextField
                        label={t('FIRST_NAME') + '*'}
                        variant="outlined"
                        value={ticket.firstname}
                        sx={{ width: '100%' }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => modifyTravellerInfo(TravellerFieldType.FIRST_NAME, event.target.value, i, tIndex)}
                        error={showError && (bookingStore.bookingFieldData[i] ? IsEmptyStr(bookingStore.bookingFieldData[i].quantities[tIndex].firstname) : true)}
                      />
                      <TextField
                        label={t('SURNAME') + '*'}
                        variant="outlined"
                        value={ticket.surname}
                        sx={{ width: '100%' }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => modifyTravellerInfo(TravellerFieldType.SURNAME, event.target.value, i, tIndex)}
                        error={showError && (bookingStore.bookingFieldData[i] ? IsEmptyStr(bookingStore.bookingFieldData[i].quantities[tIndex].surname) : true)}
                      />
                      {product.bookingFields.travellerInfo.requireDoB && <TextField
                        label={t('DOB') + '*'}
                        variant="outlined"
                        value={ticket.dob}
                        sx={{ width: '100%' }}
                        type="date"
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => modifyTravellerInfo(TravellerFieldType.DOB, event.target.value, i, tIndex)}
                        error={showError && (bookingStore.bookingFieldData[i] ? IsEmptyStr(bookingStore.bookingFieldData[i].quantities[tIndex].dob) : true)}
                      />}
                      {product.bookingFields.travellerInfo.requireHeight && <TextField
                        label={t('HEIGHT') + '*'}
                        variant="outlined"
                        value={ticket.height}
                        sx={{ width: '100%' }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => modifyTravellerInfo(TravellerFieldType.HEIGHT, event.target.value, i, tIndex)}
                        error={showError && (bookingStore.bookingFieldData[i] ? IsEmptyStr(bookingStore.bookingFieldData[i].quantities[tIndex].height) : true)}
                      />}
                      {product.bookingFields.travellerInfo.requireWeight && <TextField
                        label={t('WEIGHT') + '*'}
                        variant="outlined"
                        value={ticket.weight}
                        sx={{ width: '100%' }}
                        size="small"
                        InputLabelProps={{ shrink: true }}
                        onChange={(event) => modifyTravellerInfo(TravellerFieldType.WEIGHT, event.target.value, i, tIndex)}
                        error={showError && (bookingStore.bookingFieldData[i] ? IsEmptyStr(bookingStore.bookingFieldData[i].quantities[tIndex].weight) : true)}
                      />}
                      <Box sx={{ marginTop: -2 }}>
                        <p className={`${showError && (!bookingStore.bookingFieldData[i] || !bookingStore.bookingFieldData[i].quantities[tIndex].gender || bookingStore.bookingFieldData[i].quantities[tIndex].gender == Gender.NOT_SELECTED) && 'text-red-500'}`} style={{ marginBottom: -5 }}>{t('GENDER') + '*'}</p>
                        <RadioGroup row name="row-radio-buttons-group" value={ticket.gender} onChange={(event) => modifyTravellerInfo(TravellerFieldType.GENDER, event.target.value, i, tIndex)}>
                          <FormControlLabel value={Gender.MALE} control={<Radio />} label={t('MALE')} />
                          <FormControlLabel value={Gender.FEMALE} control={<Radio />} label={t('FEMALE')} />
                        </RadioGroup>
                      </Box>
                      {/* If is an non api product, traveller dynamic fields area */}
                      {product.bookingMethod == API_PRODUCT_METHOD && product.bookingFields.travellerInfo.travellerDynamicFields.map((label: string, labelIdx: number) => (
                        <TextField
                          label={label}
                          variant="outlined"
                          key={`api_product_traveller_dynamic_${labelIdx}`}
                          value={ticket.dynamicFields && ticket.dynamicFields[label] ? ticket.dynamicFields[label] : ""}
                          sx={{ width: '100%' }}
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          onChange={(event) => modifyTravellerDynamicInfo(label, event.target.value, i, tIndex)}
                          error={showError && (bookingStore.bookingFieldData[i].quantities[tIndex].dynamicFields ? IsEmptyStr(bookingStore.bookingFieldData[i].quantities[tIndex].dynamicFields[label]) : true)}
                        />
                      ))}
                    </Box>
                  </>
                ))}
                <p className="mt-4">{t('PLS_PROVIDE_FULL_DETAILS_OF_EACH_PASSENGER')}</p>
              </>}
            {/* End Required Traveller Info */}
            {/* If is an non api product, booking dynamic fields area */}
            {(product.bookingMethod == API_PRODUCT_METHOD && product.bookingFields.bookingInfo.bookingDynamicFields && product.bookingFields.bookingInfo.bookingDynamicFields.length > 0) && <>
              <p className={fieldLabel}>{t('BOOKING_FIELDS')} <span className="text-red-500">*</span></p>
              <Box className="grid grid-cols-3 gap-4">
                {product.bookingFields.bookingInfo.bookingDynamicFields.map((label: string, idx: number) => (
                  <TextField
                    label={label}
                    variant="outlined"
                    key={`api_product_booking_dynamic_${idx}`}
                    value={bookingField[i].dynamicFields && bookingField[i].dynamicFields[label] ? bookingField[i].dynamicFields[label] : ""}
                    sx={{ width: '100%' }}
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={(event) => modifyBookingDynamicInfo(label, event.target.value, i)}
                    error={showError && (bookingStore.bookingFieldData[i].dynamicFields ? IsEmptyStr(bookingStore.bookingFieldData[i].dynamicFields[label]) : true)}
                  />
                ))}
              </Box>
            </>}
          </Box>
        ))}
      </>}
    </Box>
  )
});

export default BookingDetailStep;
